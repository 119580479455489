:root{
    --background: #051d40;

    --content: #fff;
    --cyan: #eeecec;

    --gray: #f3f0ec;
    --gray-100: #e1e1e6;
    --gray-300: #a8a8b3;
    --gray-400: rgb(148 163 184);
    --gray-800: #29292e;
    --gray-850: #1f2729;
    --gray-900: #121214;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


@media (max-width: 1280px){
    html{
        font-size: 93.75%;
    }
}

@media (max-width: 720px){
    html{
        font-size: 87.5%;
    }
}

@media (max-width: 601px){
    html{
        font-size: 82%;
    }
}

@media (max-width: 480px){
    html{
        font-size: 70%;
    }

    img{
        max-width: 90%;
        height: auto;
    }
}

body, input, textarea, button {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

body{
    background-color: var(--cyan) !important;
    -webkit-font-smoothing: antialiased;
}

.main{
    padding: 20px;
}

.btn{
    border-radius: 0;
}

.input-group-text{
    height: 40px !important;
    border-radius: none !important;
    text-decoration: none !important;
}

.btn-light:hover{
    background-color: #adb5bd !important;
}

.btn-juntamed {
    width: 100%;
    color: #fff !important;
    background-color: var(--background) !important;
    border-color: var(--background) !important;
    margin-bottom: 6px;
}

.content__title{
    padding-bottom: 2.4rem;
    padding-top: 2rem;
}

.bg-secondary{
    background-color: #adb5bd !important;
}

.body_login{
    background: rgb(34,92,142);
    background: linear-gradient(108deg, rgba(34,92,142,1) 37%, rgba(192,50,53,1) 78%);
    height: 100vh;
}

.bd_warning{
    border: none;
    border-left: 2px rgb(168, 168, 3) solid;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 2rem;
}

.bd_success{
    border: none;
    border-left: 2px rgb(3, 168, 53) solid;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 2rem;
}

.bd_junta{
    border: none;
    border-left: 2px rgb(34,92,142) solid;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 2rem;
}

textarea{
    height: 200px !important;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.navbar-collapse {
    flex-grow: 0 !important;
}

.nav-link {
    text-decoration: none;
    color: var(--gray-400) !important;
    font-size: .95rem;
    font-weight: 400;
}

.dropdown-item:focus,
.dropdown-item:hover,
.sis:hover{
    background-color: var(--gray-850) !important;
    color: var(--gray-100) !important;
}

.navbar-expand-lg
.navbar-nav
.nav-link{
    padding: 15px .5rem !important;
}

.dropdown-item:active,
.sis:active{
    background-color: var(--gray-900) !important;
    color: var(--gray-100) !important;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after{
    display: none;
}

.content-search{
    margin: .8rem 0 !important;
}

.dropdown-toggle::after{
    float: right;
    margin-top: 15px;
}

.form-label {
    margin-top: 0.5rem;
}

input[type=text].alertErrorInput {
    border: 2px solid red;
    border-radius: 4px;
}
/* ---------------------------------------------------
    Tabela
----------------------------------------------------- */
table.dataTable{
    width: 100% !important;
}

table, th, tr {
    border: none !important;
    border-collapse: collapse !important;
}

tbody tr{
    height: 30px;
    background-color: #fff !important;
    border-radius: 8px;
    box-shadow: 4px 4px 45px 0 rgba(0,0,0,.11);
    transition: .3s ease;
}

td, th{
    color: var(--gray-800);
    padding: 12px 0;
    border-bottom: solid var(--cyan) 6px !important;
}

.content_bd{
    background-color: #fff;
    color: var(--gray-800);
    border-radius: 8px;
    box-shadow: 4px 4px 45px 0 rgba(0,0,0,.11);
    margin-top: 6px !important;
    padding: 12px 0;
    transition: .3s ease;
}

.content_bd p{
    margin: 0;
}

tbody tr:hover,
.content_bd:hover{
    background-color: rgb(148 163 184) !important;
}

.card__horizontal {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 4px 4px 45px 0 rgba(0,0,0,.11);
    margin-bottom: 8px;
    padding: 6px 12px;
}

.select2-container
.select2-selection--single{
    border: none;
    margin-right: 0;
}


.select2-selection__rendered,
.js-select-form {
    display: block;
    width: 100%;
    height: 50px;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #212529;
    background-color: #f8fafc;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
        -moz-appearance: none;
            appearance: none;
}

.select2-selection__arrow{
    display: none;
}


@media (prefers-reduced-motion: reduce) {
    .js-select-form {
        transition: none;
    }
}

.js-select-form:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.title-junta{
    color: var(--gray-850);
    font-size: 1.4rem;
    padding: 16px 0;
}

.btn-trash{
    display: block;
    font-weight: 700;
    line-height: 3;
    font-size: 1.2rem;
    color: #ff0000 !important;
    padding: 0 16px;
    height: 50px;
    bottom: 0;
    border: none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.content__itens{
    padding-bottom: 1rem;
}

.input-false{
    background-color: #e9ecef;
    color: #1f2729;
    border: 1px solid #ced4da;
    font-size: .8rem;
    font-weight: 400;
    text-decoration: none;
    height: 40px;
    width: 100%;
    position: absolute;
}

.input-false span{
    position: absolute;
    padding: 10px;
}

.input-false i {
    font-size: .9rem;
    font-weight: 400;
    color: #1f2729;
    padding: 12px;
    right: 0;
    position: absolute;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

.modal-dialog{
    max-width: 680px !important;
    font-size: 12px;
}

.btn-search{
    height: 50px;
    width: 100%;
}

.bg-gray{
    background: #adb5bd !important;
    color: #29292e !important;
}

.dropdown-user {
    position: relative;
    display: inline-block;
}

.dropdown-user span{
    padding-right: 8px;
}

.dropdown-user-content {
    display: none;
    right: 0;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}

.dropdown-user:hover
.dropdown-user-content {
    display: block;
}

.dropdown-user-content a,
.dropdown-user-content button{
    border: none;
    font-size: 12px;
    text-decoration: none;
    width: 100%;
    text-align: left;
    color: #1f2729;
}

.dropdown-user-content a:hover,
.dropdown-user-content button:hover{
    color: #86b7fe;
}

.bg-junta{
    background: var(--background) !important;
    color: #f3f0ec !important;
}

.card-dashboard{
    box-shadow: 0px 2px 6px hsla(230, 100%, 15%, 0.15);
    padding: 2rem 1.5rem;
    border-radius: 1rem;
    background-color: #fff;
}

.title_dash{
    color: var(--background);
    font-size: 1.4rem;
    font-weight: 600 !important;
    line-height: 1.2;
    text-align: center;
    padding: 0;
    margin: 0;
}

.value_dash {
    color: #295781;
    font-size: 2.2rem;
    line-height: 1.2;
    font-weight: 600;
    text-align: center;
    padding: 0;
    margin: 0;
}

.value_dash span{
    font-size: 1.2rem;
    font-weight: 300;
}

.pay-blue{
    background-color: #295781;
    color: #fff;
    font-size: 1.4rem;
    text-align: center;
    padding: 4px 0;
    height: 40px;
}

input:required:invalid {
    border: 1px solid red;
}

h4 span{
    font-weight: 700;
    font-size: 1.4rem;
}

footer{
    background-color: var(--gray-800);
    padding: 4px;
}

footer p{
    padding: 8px;
    font-size: .7rem;
    font-weight: 300;
    color: var(--gray-100);
}

td:first-child {
    padding-left: 4px;
}


ol.time {
	position: relative;
	display: block;
	margin: 40px;
	height: 4px;
	background: #051d40;
}

ol.time::before,
ol.time::after {
	content: "";
	position: absolute;
	top: -8px;
	display: block;
	width: 0;
	height: 0;
    border-radius: 10px;
	border: 10px solid #051d40;
}

ol.time::before {
	left: -5px;
}

ol.time::after {
	right: -10px;
	border: 10px solid transparent;
	border-right: 0;
	border-left: 20px solid #051d40;
    border-radius: 3px;
}

/* ---- Timeline elements ---- */
.time__line {
	position: relative;
	display: inline-block;
	float: left;
	width: 14%;
	font-size: 1rem;
    font-weight: 300;
    height: 50px;
}

.time__line .diplome {
    position: absolute;
    left: 40%;
    color: #000000;
    margin-top: 24px;
}

.point {
	content: "";
	top: -8px;
	left: 45%;
	display: block;
	width: 6px;
	height: 6px;
	border: 10px solid #0676E8;
	border-radius: 10px;
	background: #fff;
    position: absolute;
}

.point__active {
	content: "";
	top: -12px;
	left: 43%;
	display: block;
	width: 6px;
	height: 6px;
	border: 14px solid #051d40;
	border-radius: 16px;
	background: #fff;
    position: absolute;
}

.time__line:hover {
	cursor: pointer;
    color: #0676E8;
}

.status_fim{
    background-color: #198754;
}

.status_abertura{
    background-color: #adb5bd;
}

.status_assistente{
    background-color: #0676E8;
}

.status_cancelada{
    background-color: #ff0000;
}

.status_comunicado{
    background-color: #0000ff;
}

.status_itens{
    background-color: #ffc107;
}

.status_desempatador{
    background-color: #051d40;
}

.status_reaberta{
    background-color: #d13a3a;
}

.status_parecer{
    background-color: #ec8423;
}

.status_fim,
.status_abertura,
.status_assistente,
.status_cancelada,
.status_comunicado,
.status_itens,
.status_desempatador,
.status_parecer,
.status_reaberta{
    width: 28px;
    height: 28px;
}


.switch-field {
	display: block;
	margin-bottom: 36px;
	overflow: hidden;
}

.switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.switch-field label {
	background-color: #e4e4e4;
	color: #444;
	font-size: .85rem;
    font-weight: 600;
    height: 40px;
	text-align: center;
	padding: 8px 15px;
	transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
	cursor: pointer;
}

.switch-field input:checked + label {
	background-color: #272727;
	box-shadow: none;
    color: #FFF;
}
